import { createSlice } from "@reduxjs/toolkit";

const counterStore = createSlice({
    name: 'counter',
    initialState:{
        count: 0
    },
    reducers:{
        increment(state){
            state.count++
        },
        decrement(state){
            state.count--
        },
        incrementNum(state, action){
            state.count = state.count + action.payload
        }
    }
})

const {increment, decrement, incrementNum} = counterStore.actions;
const counterReducer = counterStore.reducer;
export {increment, decrement, incrementNum}
export default counterReducer;