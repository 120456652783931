import { Outlet } from "react-router-dom";
import './index.scss';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import classNames from "classnames";
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import IosShareIcon from '@mui/icons-material/IosShare';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { useSelector, useDispatch } from "react-redux";
import { setCurTab } from "@/store/modules/userStore";
import Share from "../Share";
import Card from "../Card";
import { postAction } from "@/api/manage";

function Layout() {

  const navigate = useNavigate();
  const {curTab, userInfo} = useSelector(state => state.user);
  const [shareDisp, setShareDisp] = useState(false);
  const [cardDisp, setCardDisp]  = useState(false);
  const dispatch = useDispatch();
  
  const tabList = [
    {
      type: '/',
      name: 'ホーム',
      icon: <HomeIcon/>
    },
    {
      type: '/card',
      name: '学生証',
      icon: <AssignmentIndIcon/>
    },
    {
      type: '/share',
      name: '共有',
      icon: <IosShareIcon/>
    },
    {
      type: '/password_reset',
      name: '設定',
      icon: <DensityMediumIcon/>
    }
  ]

  const changeTab = (type) => {
    if (type == '/share') {
      if (process.env.REACT_APP_SHARE_CHECK == '0' || userInfo.hasShare) {
        setCardDisp(false);
        setShareDisp(!shareDisp);
      }
    } else if (type == '/card') {
      setCardDisp(!cardDisp);
      setShareDisp(false);
    } else {
      setCardDisp(false);
      setShareDisp(false);
      dispatch(setCurTab(type));
      navigate(type)
    }
  }
  const handleCloseShare = () => {
    setShareDisp(false);
  }
  const handleCloseCard = () => {
    setCardDisp(false);
  }

  return (
    <div className="layout">
      <div className="container">
          <Outlet/>
          {shareDisp && <Share handleCloseShare={handleCloseShare}/>}
          {cardDisp &&  <Card handleCloseCard={handleCloseCard}/>}
      </div>
      
      <div className="footer">        
        <div className='tarbar_bar'>
          {tabList.map((item) => {
            return (
              <div className={classNames('tabbar_item', curTab == item.type && 'tabbar_item_active')} key={item.name} onClick={() => changeTab(item.type)}>
                <div className='tabbar_item_icon'>{item.icon}</div>
                <div className='tabbar_item_text'>{item.name}</div>
              </div>
            )            
          })}
        </div>
        <div className='tabbar_bottom'></div>      
      </div>
    </div>
  )
}

export default Layout;