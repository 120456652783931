import { useSearchParams, useNavigate } from "react-router-dom";
import './index.scss';
import { useEffect, useState } from "react";
import BarLine from "@/compoment/BarLine";
import classNames from "classnames";
import { LineStyle } from "@mui/icons-material";
import { Collapse, List, Button, styled } from "@mui/material";
import { postAction } from "@/api/manage";
import { getToken, clearToken, getUserInfo } from "@/utils/token";
import { showErrDialog } from "@/utils/dialog";
import { getWeekDataList } from "@/utils/date";
import MenuIcon from '@mui/icons-material/Menu';

function Home() {

  const navigate = useNavigate();
  const subjectArr = ['国語', '数学', '理科', '社会', '英語'];
  const subjectParams = {
    '国語': 'japanese',
    '数学': 'math',
    '理科': 'science',
    '社会': 'social',
    '英語': 'english'
  };
  const subjectMap = {
    'science': '理科',
    'math': '数学',
    'japanese': '国語',
    'social': '社会',
    'english': '英語'
  };

  const [userId, setUserId] = useState('');
  // [{sub:'', name:'', point:'', examat: '}]
  const [testInfo, setTestInfo] = useState([]);

  const [params] = useSearchParams();
  const [hasPhoto, setHasPhoto] = useState(false); // 写真があるか
  const [photos, setPhotos] = useState([]);

  const [navText, setNavText] = useState('算数');
  const [navIndex, setNavIndex] = useState(1);
  const [navOption, setNavOption] = useState(null);
  const [hasNavData, setHasNavData] = useState(true);
  const [navShow, setNavShow] = useState(false);

  const [libText, setLibText] = useState('数学');
  const [libIndex, setLibIndex] = useState(1);
  const [libOption, setLibOption] = useState(null);
  const [hasLibData,  setHasLibData] = useState(true);
  const [libShow, setLibShow] = useState(false);

  const [hasToppanData, setHasToppanData] = useState(true);

  const [open, setOpen] = useState(false);

  // 学生名
  const [name, setName] = useState("");
  // 学校名
  const [school, setSchool] = useState("");
  // 学年
  const [schoolYear, setSchoolYear] = useState("");
  // 組
  const [classNumber, setClassNumber] = useState("");
  // 番号
  const [sNumber, setSNumber] = useState("");

  const CuBtn = styled(Button)(
    () => ({   
      width: '100%',
      height: '2.5rem',
      fontSize:'0.8rem',
      backgroundColor: '#FFF',
      color: 'black',
      boxShadow: '0px 0 0.85vh 0.12vw #DDD',
      borderRadius: "4px",
      '&:hover': {backgroundColor:'#FFF'},
    })
  )


  useEffect(() => {
    document.title = '教育PDS実証実験用アプリ'
    var userInfo = getUserInfo();
    if (userInfo && userInfo.name) {
      setUserId(userInfo.userId)
      setName(userInfo.name)
      // 学生情報を取得する
      getStudentInfo(userInfo.userId);
      // NAV or LIB 権限
      getDataAvailable(userInfo.userId);
      // 科目
      getSubjectInfo(userInfo.userId);
    }


    // NAVデータを取得する
    // let optionData = makeBarLineOption(100, ['1/21', '1/22', '1/23', '1/24', '1/25'],[60, 50, 80, 90, 12, 11],[12, 10, 70, 90, 12, 11],[88, 62, 78, 71, 70], ['#b6c6f2','#4474ed','#15499E']);
    // setNavText(subjectArr[navIndex]);
    // setNavOption(optionData);

    // LIBデータを取得する
    // let optionLibData = makeBarLineOption(100, ['1/21', '1/22', '1/23', '1/24', '1/25'],[0.6, 0.5, 0.8, 0.9, 1.2, 1.1],[1.2, 1, 0.7, 0.9, 1.2, 1.1],[88, 62, 78, 71, 70], ['#f2b6c2','#e64363','#15499E']);
    // setLibText(subjectArr[libIndex]);
    // setLibOption(optionLibData);

    // TOPPANデータを取得する


  }, [])

  const getNavData = (uid, subject) => {
    if (uid == undefined) {
      uid = userId
    }
    if (subject == undefined) {
      subject = subjectArr[0]
    }
    var nowWeek = getWeekDataList(new Date());
    var curMondayDate = new Date(nowWeek[0]);
    var lastMondayDate = new Date(curMondayDate.setDate(curMondayDate.getDate() - 7));
    var preWeek = getWeekDataList(lastMondayDate);
    postAction('find-navima-detail', {userId: uid, subject: subjectParams[subject], epochday: nowWeek[0]}).then(res => {
      var xData = [];
      for (var i=0; i<5; i++) {
        xData.push(nowWeek[i].substr(5).replace('-', '/'))
      }
      var preData = [0,0,0,0,0];
      var curData = [0,0,0,0,0];
      var totalData = [0,0,0,0,0];
      if (res.body) {
        for (var i=0; i<5; i++) {
          var pdate = nowWeek[i];
          var preDate = preWeek[i];
          if (res.body[pdate] && res.body[pdate]['question_count']) {
            totalData[i] = res.body[pdate]['question_count'];
            curData[i] = (res.body[pdate]['question_right']*100/res.body[pdate]['question_count']).toFixed(1)
          }
          if (res.body[preDate] && res.body[preDate]['question_count']) {
            preData[i] = (res.body[preDate]['question_right']*100/res.body[preDate]['question_count']).toFixed(1)
          }
        }
      }
      console.log("===========", xData, preData, curData, totalData)
      let optionData = makeBarLineOption(100, xData,preData,curData,totalData, ['#b6c6f2','#4474ed','#15499E']);
      setNavOption(optionData);

    }).catch(err=>{})

  }
  const getLibData = (uid, subject) => {
    if (uid == undefined) {
      uid = userId
    }
    if (subject == undefined) {
      subject = subjectArr[0]
    }
    var nowWeek = getWeekDataList(new Date());
    var curMondayDate = new Date(nowWeek[0]);
    var lastMondayDate = new Date(curMondayDate.setDate(curMondayDate.getDate() - 7));
    var preWeek = getWeekDataList(lastMondayDate);
    postAction('find-libry-detail', {userId: uid, subject: subjectParams[subject], epochday: nowWeek[0]}).then(res => {
      var xData = [];
      for (var i=0; i<5; i++) {
        xData.push(nowWeek[i].substr(5).replace('-', '/'))
      }
      var preData = [0,0,0,0,0];
      var curData = [0,0,0,0,0];
      var totalData = [0,0,0,0,0];
      if (res.body) {
        for (var i=0; i<5; i++) {
          var pdate = nowWeek[i];
          var preDate = preWeek[i];
          if (res.body[pdate] && res.body[pdate]['question_count']) {
            totalData[i] = res.body[pdate]['question_count'];
            curData[i] = (res.body[pdate]['question_right']*100/res.body[pdate]['question_count']).toFixed(1)
          }
          if (res.body[preDate] && res.body[preDate]['question_count']) {
            preData[i] = (res.body[preDate]['question_right']*100/res.body[preDate]['question_count']).toFixed(1)
          }
        }
      }
      console.log("===========", xData, preData, curData, totalData)
      let optionData = makeBarLineOption(100, xData,preData,curData,totalData, ['#b6c6f2','#4474ed','#15499E']);
      setLibOption(optionData);

    }).catch(err=>{})
  }

  const handleNavUp = () => {
    let curNavIndex = navIndex === 0 ? subjectArr.length - 1 : navIndex - 1;
    setNavIndex(curNavIndex)
    setNavText(subjectArr[curNavIndex]);
    getNavData(userId, subjectArr[curNavIndex]);    
  }

  const handleNavDown = () => {    
    let curNavIndex = navIndex === subjectArr.length - 1 ? 0 : navIndex + 1;
    setNavIndex(curNavIndex)
    setNavText(subjectArr[curNavIndex]);
    getNavData(userId, subjectArr[curNavIndex]);   
  }

  const handleLibUp = () => {
    let curLibIndex = libIndex === 0 ? subjectArr.length - 1 : libIndex - 1;
    setLibIndex(curLibIndex);
    setLibText(subjectArr[curLibIndex])
    getLibData(userId, subjectArr[curLibIndex]);   
  }

  const handleLibDown = () => {    
    let curLibIndex = libIndex === 4 ? subjectArr.length - 1 : libIndex + 1;
    setLibIndex(curLibIndex);
    setLibText(subjectArr[curLibIndex])
    getLibData(userId, subjectArr[curLibIndex]);  
  }

  const handleOpen = () => {
    setOpen(!open);
  }

  const logout = () => {
    postAction('/logout', {token: getToken()}).then(res => {
      clearToken();
      navigate("/login");
    }).catch(err => {
      showErrDialog("ログアウト失敗");
    })
  }

  const getSubjectBackgroundColor = (index) => {
    if (index === 0) {
      return '#e64363'
    }
    if (index === 1) {
      return '#4474ed'
    }
    if (index === 2) {
      return '#00a99d'
    }
    if (index === 3) {
      return '#e8b500'
    }
    if (index == 4) {
      return '#7400e8'
    }
    return ''
  }

  const makeBarLineOption = (yMax, xAxis, barData1, barData2, lineData, dataColor) => {
    let option = {
      grid: {
        top: '15%',
        left: '1%', 
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: xAxis,
          axisPointer: {
            type: 'shadow'
          },
          axisLabel:{
            interval: 0
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          alignTicks: true,
        },
        {
          type: 'value',
          name: '',
          alignTicks: true,
          min: 0,
          max: yMax,
          interval: 25
        }
      ],
      series: [
        {
          type: 'bar',
          yAxisIndex: 1,
          barWidth: '30%',
          barGap:-0.2,
          z:2,
          itemStyle:{
            color: dataColor[0]
          },
          data: barData1
        },
        {
          type: 'bar',
          yAxisIndex: 1,
          barWidth: '60%',
          z:1,
          itemStyle:{
            color: dataColor[1]
          },
          data: barData2
        },
        {
          type: 'line',
          yAxisIndex: 0,
          symbol: 'circle',
          itemStyle:{
            color: dataColor[2]
          },
          lineStyle:{
            color: dataColor[2]
          },
          data: lineData
        }
      ]
    };

    return option;
  }

  const randData = (max) => {
    return (Math.random()*max).toFixed(1)
  }

  const getStudentInfo = (userId) => {
    postAction('find-student', {userId}).then(res => {
      setSchool(res.school_name)
      setSchoolYear(res.school_year);
      setClassNumber(res.class_number);
      setSNumber(res.student_number);
    }).catch(err=>{})
  }

  const getDataAvailable = (userId) => {
    postAction('get-user-available-service', {userId}).then(res => {
      if (res.navima_id != null && res.navima_id != undefined) {
        setNavShow(true);
        getNavData(userId);
      } else {
        setNavShow(false);
      }
      if (res.libry_id != null && res.libry_id != undefined) {
        setLibShow(true);
        getLibData(userId);
      } else {
        setLibShow(false);
      }
    }).catch(err=>{})
  }

  const getSubjectInfo = (userId) => {    
    postAction('find-exam-subject', {userId}).then(res => {      
      // 科目を取得する
      setTestInfo([]);
      var toppanInfo = [];
      if (res.subject && res.subject.length > 0) {
        res.subject.forEach(item => {
          if (subjectMap[item]) {
            toppanInfo.push({
              sub: item,
              name: subjectMap[item],
              point: '',
              examat: ''
            })
          }
        })        
        // 点数を取得する
        var promises = [];
        for (var i=0; i<toppanInfo.length; i++) {
          var cur = toppanInfo[i];
          promises.push(postAction('find-exam', {userId, subject: cur['sub']}))
        }
        Promise.all(promises).then(results => {
          results.forEach(item => {
            var idx = toppanInfo.findIndex(obj => obj.sub == item[0]['subject'])
            if (idx != -1) {
              toppanInfo[idx]['point'] = item[0]['exam_mark']
              toppanInfo[idx]['examat'] = item[0]['exam_at']
            }
          })    
          setTestInfo(toppanInfo)
        }).catch(err=>{})
      }
    }).catch(err=>{})
  }

  const subjectColor = (sub) => {
    if (sub == 'math') {
      return "#4474ed"
    }
    if (sub == 'science') {
      return "#00a99d"
    }
    if (sub == 'japanese') {
      return "#e64363"
    }
    if (sub == 'social') {
      return "#e8b500"
    }
    if (sub == 'english') {
      return "#7400e8"
    }
    return "#e64363"
  }
  const subjectIcon = (point) => {
    if (point >= 90) {
      return require(`@/assets/star.png`)
    }
    if (point >= 70) {
      return require(`@/assets/sunny.png`)
    }
    if (point >= 50) {
      return require(`@/assets/cloud.png`)
    }
    return require(`@/assets/rainy.png`)
  }

  return (
    <div className="home">
      <div className="header">
        <div className="id">実証実験用アプリ</div>
        <div className="icon"  onClick={handleOpen}>
          <MenuIcon />
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit className="menu">
            <List component="div" disablePadding>
              <CuBtn
                onClick={logout}
                >ログアウト</CuBtn>
            </List>
        </Collapse>
      </div>
      <div className={classNames('card', !hasPhoto && 'small')}>
        <div className="title">
          <div className="title_left"><img src={require(`@/assets/icon-school.png`)} /><span>生徒証</span></div>
          {/* <div>
            <img src={require(`@/assets/dumy_logo.png`)} />
          </div> */}
        </div>
        <div className="middle">
          <div className="info">
            <img src={require(`@/assets/icon-mark-big.png`)} />
            {hasPhoto && <div>NO M124568</div>}
            <div>{schoolYear}学年　{classNumber}組　{sNumber}番</div>
            <div className="bigger">{name}</div>
            {
              hasPhoto && (
                <>
                  <div>さいとう　かなこ</div>
                  <div>2006/11/25 生</div>
                </>
              )
            }            
          </div>
          {
            hasPhoto && (
              <div className="pic">
              <img src={require(`@/assets/dumy_photo.png`)}/>
            </div>
            )
          }
        </div>        
        <div className="bottom">
          <div className="tip1">本校の生徒であることを証明する</div>
          <div className="tip2">{school}</div>
          <div className="tip2">&nbsp;</div>
          <div className="sign"><img src={require(`@/assets/icon_sign.png`)} /></div>
        </div>
      </div>
      <div className="widgits">
        {
          navShow &&
          <div className={classNames("widgit_item", hasNavData && "has_content")}>
            {!hasNavData && <img src={require(`@/assets/widgit_nav.png`)} />}
            {hasNavData && (
              <>
                <div className="left">
                  <img src={require(`@/assets/widgit_nav.png`)} />
                  <img className="icon up" src={require(`@/assets/icon_up.png`)} />
                  {/* <img className="icon up" src={require(`@/assets/icon_up.png`)} onClick={handleNavUp} /> */}
                  <div className="content" style={{backgroundColor: getSubjectBackgroundColor(navIndex)}}>{navText == '数学' ? '算数' : navText}</div>
                  <img className="icon down" src={require(`@/assets/icon_down.png`)} />
                  {/* <img className="icon down" src={require(`@/assets/icon_down.png`)} onClick={handleNavDown}/> */}

                </div>
                <div className="right">
                  <BarLine option={navOption}></BarLine>
                </div>
              </>
            )}
          </div>
        }
        {
          libShow && 
          <div className={classNames("widgit_item", hasLibData && "has_content")}>
            {!hasLibData && <img src={require(`@/assets/widgit_lib.png`)} />}
            {hasLibData && (
              <>
                <div className="left">
                  <img src={require(`@/assets/widgit_lib.png`)} />
                  <img className="icon up" src={require(`@/assets/icon_up.png`)} />
                  {/* <img className="icon up" src={require(`@/assets/icon_up.png`)} onClick={handleLibUp} /> */}
                  <div className="content" style={{backgroundColor: getSubjectBackgroundColor(libIndex)}}>{libText}</div>
                  <img className="icon down" src={require(`@/assets/icon_down.png`)} />
                  {/* <img className="icon down" src={require(`@/assets/icon_down.png`)} onClick={handleLibDown}/> */}

                </div>
                <div className="right">
                  <BarLine option={libOption}></BarLine>
                </div>
              </>
            )}
          </div>
        }
        <div className={classNames("widgit_item", hasToppanData && "has_content has_content_toppan")}>
          {!hasToppanData &&  <img src={require(`@/assets/widgit_toppan.png`)} />}
          {hasToppanData && (
            <>
              <div className="toppan_title">
                <img src={require(`@/assets/icon-school.png`)} />
                <p>{school}</p>
              </div>
              <div className="toppan_subject">
                <div className="left">テスト結果</div>
                <div className="right">
                  {
                    testInfo.map(item => (
                      <div className="sub_title" style={{backgroundColor: subjectColor(item.sub)}}>{item.name}</div>
                    ))
                  }
                </div>
              </div>
              <div className="toppan_subject">
                <div className="left"></div>
                <div className="right">
                  {
                    testInfo.map(item => (
                      <div className="sub_date">{item.examat && item.examat.length >= 10 ? item.examat.substr(5, 5).replace("-", "/") : '' }</div>
                    ))
                  }
                </div>
              </div>
              <div className="toppan_subject">
                <div className="left"></div>
                <div className="right">
                  {
                    testInfo.map(item => (
                      <div className="sub_icon"><img src={subjectIcon(item.point)}/></div>
                    ))
                  }
                </div>
              </div>
              <div className="toppan_subject aligncenter">
                <div className="left light">点数</div>
                <div className="right">
                  {
                    testInfo.map(item => (
                      <div className="sub_point">{item.point}</div>
                    ))
                  }
                </div>
              </div>
              <div className="toppan_subject aligncenter">
                <div className="left light">平均点</div>
                <div className="right">
                  {
                    testInfo.map(item => (
                      <div className="sub_avg_point">-</div>
                    ))
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Home;