import ReactDOM from 'react-dom/client';
import Error from '@/page/Dialog/Error';

function showErrDialog(val) {
  var dom = document.createElement('div')
  dom.setAttribute('id', 'errorDialog')
  document.body.appendChild(dom)
  ReactDOM.createRoot(dom).render(
    <Error msg={val}/>
  )
}
function hideErrDialog() {
  document.body.removeChild(document.getElementById('errorDialog'))
}

export { showErrDialog, hideErrDialog };