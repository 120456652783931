export function getCurrentDate() {
  var now = new Date();
  return `${now.getFullYear()}-${(now.getMonth() + 1 + '').padStart(2, '0')}-${(now.getDate() + '').padStart(2, '0')}`;
}

export function getWeekDataList(date) {
  var weekList = [];
  if (date.getDay() == '0') {
    date.setDate(date.getDate() - 6);
  } else {
    date.setDate(date.getDate() - date.getDay() + 1);
  }
  var myDate = (date.getDate() + '').padStart(2, '0');
  var myMonth = (date.getMonth() + 1 + '').padStart(2, '0');
  weekList.push(date.getFullYear() + "-" + myMonth + "-" + myDate);
  for (var i=0; i<6; i++) {
    date.setDate(date.getDate() + 1);
    myDate = (date.getDate() + '').padStart(2, '0');
    myMonth = (date.getMonth() + 1 + '').padStart(2, '0');
    weekList.push(date.getFullYear() + "-" + myMonth + "-" + myDate);
  }
  return weekList;
}