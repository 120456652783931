import './index.scss';

function CustInput(props){

  const {error, ...rest} = props;
  return (
    <>
      <input className="form_input" {...rest}></input>
      {error && <div className="error">{error}</div>}
    </>
  )
}

export default CustInput;