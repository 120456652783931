
import { useState, useEffect } from 'react';
import './index.scss';
import { getUserInfo } from '@/utils/token';
import { postAction } from '@/api/manage';


function Card(props) {

  const {handleCloseCard} = props;
  const handleWrapperClick = (e) => {
    if (e.target && e.target.classList && e.target.classList[0] == 'card_wrapper') {
      handleCloseCard()
    }    
  }

  // 学生名
  const [name, setName] = useState("");
  // 学校名
  const [school, setSchool] = useState("");
  // 学年
  const [schoolYear, setSchoolYear] = useState("");
  useEffect(() => {
    var userInfo = getUserInfo();
    if (userInfo && userInfo.name) {
      setName(userInfo.name)
      getStudentInfo(userInfo.userId);
    }    
  }, [])

  const getStudentInfo = (userId) => {
    postAction('find-student', {userId}).then(res => {
      setSchool(res.school_name)
      setSchoolYear(res.school_year);
    }).catch(err=>{})
  }

  return (
    <div className='card_wrapper' onClick={handleWrapperClick}>
      <div className='cardContainer'>
        <h1>オンライン証明</h1>
        <p>以下のQRコードをスキャンすることで、あなた自身を証明することが出来ます。</p>
        <div>
            <img src={require(`@/assets/qr.png`)} alt="QRコード" />
        </div>
        <p>以下の情報を証明します</p>
        <div className="info">
          <p>氏名： {name}</p>
          <p>学校名： {school}</p>
          <p>学年： {schoolYear}年生</p>
        </div>
      </div>
    </div>
  )
}

export default Card;