import { useState } from 'react';
import './Error.scss';
import { Dialog,Alert } from '@mui/material';

function Error(props) {

  const {msg} = props;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const editMsg = () => {
    if (msg) {
      return msg;
    } else {
      return (
        <div>
          エラーが発生しました。
        </div>
      );
    }    
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Alert severity="error" sx={{alignItems: 'center', '& .MuiAlert-message':{wordBreak: 'break-word'}}}>          
          {editMsg()}
        </Alert>
    </Dialog>
  )
}

export default Error;