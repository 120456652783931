import { createBrowserRouter } from "react-router-dom";

import Login from "../page/Login";
import PasswordReset from "@/page/PasswordReset";
import PasswordResetComplete from "@/page/PasswordResetComplete";
import NotFound from "@/page/NotFound";

import Layout from "@/page/Layout";
import Home from "../page/Home";
import Card from "@/page/Card";
import Share from "@/page/Share";
import Setting from "@/page/Setting";
import AuthRoute from "./AuthRoute";

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    // {
    //     path: '/password_reset',
    //     element: <PasswordReset />
    // },
    // {
    //     path: '/password_reset_complete',
    //     element: <PasswordResetComplete />
    // },
    {
        path: '/',
        element: <Layout/>,
        children:[
            {
                index: true,
                element: <AuthRoute><Home /></AuthRoute>
            },
            // {
            //     path: '/card',
            //     element: <AuthRoute><Card/></AuthRoute>
            // },
            // {
            //     path: '/share',
            //     element: <AuthRoute><Share/></AuthRoute>
            // },
            {
                path: '/password_reset',
                element: <AuthRoute><PasswordReset/></AuthRoute>
            },
            {
                path: '/password_reset_complete',
                element: <AuthRoute><PasswordResetComplete/></AuthRoute>
            },
        ]
    },
    {
        path: '*',
        element: <NotFound/>
    }
])

export default router;