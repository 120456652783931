const Messages = {
  "E-001": "ユーザー名が間違っています。",
  "E-002": "パスワードが間違っています。",
  "E-003": "現在のパスワードを入力してください。",
  "E-004": "現在のパスワードが異なっています。",
  "E-005": "新しいパスワード（確認用）には新しいパスワードと同じ内容を入力してください。",
  "E-006": "新しいパスワードは10文字以上で半角の英字・数字・記号を最低1つずつ使用してください。",
  "E-007": "新しいパスワードを入力してください。",
  "E-008": "新しいパスワード（確認用）を入力してください。",
  "E-009": "新しいパスワードには現在のパスワードと同じ内容を入力しないでください。",

  "E-501": "TOKEN検証失敗。",
  "E-502": "ユーザ情報が取得できません。",

  "E-901": "情報の設定に失敗しました。",
};

const Msg = {
  get(msgCode, param1, param2) {
    let msg = Messages[msgCode] || "";
    msg = msg.replace("{s (項目名)}", param1);
    return msg;
  },
};

export default Msg;