
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setReqToken, setUser } from "@/store/modules/userStore";
import './index.scss';
import _ from "lodash";
import { useEffect, useState } from "react";
import { checkStringValidator, passwordValidator } from "@/utils/validator"
import Msg from '@/common/Message'
import CustInput from "@/compoment/CustInput";
import CustButton from "@/compoment/CustButton";
import { Alert } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getAction, postAction } from "@/api/manage";
import { showErrDialog } from "@/utils/dialog";

function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = '教育PDS実証実験用アプリ'
  })

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false);
  const [err, setErr] = useState(false); // 登録APIのレスポンスエラー

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    console.log("==dologin===", username, password)
    if (validLogin()) {
      postAction('/login', {username, password}).then(res => {
        if (res.access_token) {
          dispatch(setReqToken({token:res.access_token, refreshToken: res.refresh_token})); 
          getUserInfo(res.access_token);          
        }
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.errorMessage) {
          setErr(Msg.get("E-001"));
        } else {
          setErr(Msg.get("E-001"));
        }        
      })
    }
  }

  const getUserInfo = (token) => {
    postAction('/inspection', {token}).then(res => {
      if (res.active) {
        postAction('/get-user-detail', {userid: res.sub}).then(res => {
          if (res.userName) { // ユーザ情報取得できる場合
            var hasShare = false;
            if (res.groupRoles && res.groupRoles.length > 0) {
              for (var i=0; i<res.groupRoles.length; i++) {
                if (res.groupRoles[i]['groupName'] == res.userName) {
                  hasShare = true
                  break;
                }
              }
            }
            dispatch(setUser({userInfo:{userId: res.userId, userName: res.userName, name: res.name, hasShare: hasShare, preferredUsername: res.preferredUsername ? res.preferredUsername : ''}}))
            navigate('/')
          } else {
            showErrDialog(Msg.get("E-502"))
          }
        }).catch(err => {showErrDialog(Msg.get("E-502"))})
      } else {
        showErrDialog(Msg.get("E-501"))
      }
    }).catch(err => {showErrDialog(Msg.get("E-501"))})
  }

  const validLogin = () => {
    let checkRet = true;
    // username check
    if (_.isEmpty(username)) {
      setUsernameError(Msg.get("E-001"));
      checkRet = false;
    } else if (!checkStringValidator(username)) {
      setUsernameError(Msg.get("E-001"));
      checkRet = false;
    } else {
      setUsernameError("");
    }

    // password check
    if (_.isEmpty(password)) {
      setPasswordError(Msg.get("E-002"));
      checkRet = false;
    }
    // else if (!passwordValidator(password)) {
    //   setPasswordError(Msg.get("E-002"));
    //   checkRet = false;
    // } 
    else {
      setPasswordError("");
    }
    return checkRet;
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className="wrapper">
      {/* <div className="header">
        <img src={require('@/assets/logo.png')} className="icon"></img>
        <span className="icon_text">LOGO</span>
      </div> */}
      <div className="login_box" >
        {err && 
          <Alert variant="filled" severity="error">
            {err}
          </Alert>
        }
        <div className="form_item">
          <div className="form_label">ユーザー名</div>          
          <CustInput type="text" error={usernameError} placeholder="" maxLength={100} value={username} onChange={(e) => {setUsername(e.target.value)}}></CustInput>
        </div>
        <div className="form_item">
          <div className="form_label">パスワード</div>     
          <CustInput type={showPassword ? 'text' : 'password'} error={passwordError} placeholder="" maxLength={100} value={password} onChange={(e) => {setPassword(e.target.value)}}></CustInput>
          <div className="icon" onClick={handleClickShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</div>
        </div>
        {/* <a className="pass_change_link" href="/password_reset">パスワードを変更する</a> */}
        <div className="pass_change_link"></div>
        <CustButton onClick={handleLogin}>ログイン</CustButton>
      </div>
    </div>
  )
}

export default Login;