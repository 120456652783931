import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './index.scss';
import { FormGroup, FormControlLabel, Checkbox, Button, Select, MenuItem, Collapse, Alert, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { getUserInfo } from '@/utils/token';
import { postAction } from '@/api/manage';
import { useSelector } from "react-redux";


function Share(props) {
  
  const userInfoStore = useSelector(state => state.user)['userInfo'];

  useEffect(() => {
    var userInfo = getUserInfo();
    if (userInfo && userInfo.name) {
      setUserId(userInfo.userId);
      getStudentInfo(userInfo.userId);
      getDataAvailable(userInfo.userId)
    }    
  }, []);

  const [userId, setUserId] = useState("");
  const [school, setSchool] = useState("");
  const [service, setService] = useState("");
  const getStudentInfo = (userId) => {
    postAction('find-student', {userId}).then(res => {
      setSchool(res.school_name)
    }).catch(err=>{})
  }

  const navigate = useNavigate();
  const {handleCloseShare} = props;
  const [destination, setDestination] = useState('-');
  const [state, setState] = useState({
    name: false,
    testResults: false,
    learningData: false
  })
  const [alertOpen, setAlertOpen] = useState(false);
  const [warnOpen, setWarnOpen] = useState(false);
  const [errOpen, setErrOpen] = useState(false);

  const handleChange = (event) => {
    if (event.target.name == "testResults" && event.target.checked) { //「個人データ」に未チェックの状態で「学校テスト結果」にチェックを入れると「個人データ」にチェックが入る
      setState({
        ...state,
        'name': true,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.name == "name" && !event.target.checked && state.testResults) { // 「学校テスト結果」チェック場合、「個人データ」チェックを外すと、エラー提示
      setWarnOpen(true);
    } else {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    }
  };
  const handleSelectChange = (event) => {
    setDestination(event.target.value)
    var cache = sessionStorage.getItem("share" + "-" + userId)
    if (cache) {
      cache = JSON.parse(cache)
      if (cache[event.target.value]) {
        setState({
          name: cache[event.target.value]['name'],
          testResults: cache[event.target.value]['testResults'],
          learningData: cache[event.target.value]['learningData']
        })
      } else {
        setState({
          name: false,
          testResults: false,
          learningData: false
        })
      }
    } else {
      setState({
        name: false,
        testResults: false,
        learningData: false
      })
    }
  }

  const CuBtn = styled(Button)(
    () => ({   
      color: '#FFFFFF',   
      padding:'0.625rem 1.25rem',
      border:'none',
      borderRadius:'0.25rem',
      backgroundColor:'#4CAF50',
      fontSize:'1rem',
      marginTop: '1rem',
      '&:hover': {backgroundColor:'#4CAF50'},
      '&.Mui-disabled':{color: '#FFFFFF', backgroundColor:'#CCCCCC'}
    })
  )
  const discMap = {
    '九州文化学園小学校': process.env.REACT_APP_SCHOOL_S,
    '十文字中学校': process.env.REACT_APP_SCHOOL_M,
    '高田高校': process.env.REACT_APP_SCHOOL_L
  }
  const handleClick = () => {
    var discId = discMap[school];
    if (discId == null || discId == undefined) {
      if (destination == '全教研') {
        discId = process.env.REACT_APP_SCHOOL_S
      } else if (school.indexOf('中学校') != -1){
        discId = process.env.REACT_APP_SCHOOL_M
      } else {
        discId = process.env.REACT_APP_SCHOOL_L
      }
    }

    var discData = [];
    if (state.name) {
      discData.push('personal');
    }
    if (state.testResults) {
      discData.push('school');
    }
    if (state.learningData) {
      discData.push(service);
    }
    console.log("==params==", state, destination)
    var params = {userId: userId, disclosureUser: [discId], disclosureData: discData}
    if (userInfoStore.preferredUsername) {
      params.userId = userInfoStore.preferredUsername
    } else {
      params.userId = (userInfoStore.userId).toString()
    }
    postAction('set-user-consent', params).then(res => {
      if (res.result) {
        setAlertOpen(true)
        var json = {};
        var cache = sessionStorage.getItem("share" + "-" + userId)
        if (cache) {
          cache = JSON.parse(cache)
          json = cache;
        }
        
        json[destination] = {
          ...state
        } 
        sessionStorage.setItem("share" + "-" + userId, JSON.stringify(json))
      } else {
        setErrOpen(true);
      }
    }).catch(e=>{setErrOpen(true);})
  }
  const handleWrapperClick = (e) => {
    if (e.target && e.target.classList && e.target.classList[0] == 'share_wrapper') {
      handleCloseShare()
    }    
  }
  const handleClose = () => {
    setAlertOpen(false)
    handleCloseShare()
    navigate("/")
  }

  const handleWarnClose = () => {
    setWarnOpen(false);
  }
  const handleErrClose = () => {
    setErrOpen(false);
  }

  const getDataAvailable = (userId) => {
    postAction('get-user-available-service', {userId}).then(res => {
      if (res.navima_id != null && res.navima_id != undefined) {
        setService('navima')
      }
      if (res.libry_id != null && res.libry_id != undefined) {
        setService('libry')        
      }
    }).catch(err=>{})
  }

  return (
    <div className="share_wrapper" onClick={handleWrapperClick}>      
      <Collapse in={alertOpen} className='share_alert'>
        <Alert action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}><CloseIcon fontSize="inherit"></CloseIcon></IconButton>
          }>あなたの個人情報提供内容を更新しました！</Alert>
      </Collapse>
      <Collapse in={warnOpen} className='share_alert'>        
        <Alert severity="warning" action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleWarnClose}><CloseIcon fontSize="inherit"></CloseIcon></IconButton>
          }>このチェックボックスは「学校テスト結果」が選択されているため、チェックを外すことができません。個人データを共有したくない場合、「学校テスト結果」のチェックを外してください！</Alert>
      </Collapse>     
      <Collapse in={errOpen} className='share_alert'>        
        <Alert severity="warning" action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleErrClose}><CloseIcon fontSize="inherit"></CloseIcon></IconButton>
          }>個人情報更新失敗！</Alert>
      </Collapse>    
      <div className="share_container">
        <h1>個⼈情報の提供</h1>
        <p>あなたの個人情報を提供します。提供先・提供情報を選択して「提供する」を押してください。</p>
        <div className="checkbox-group">
          <h3>提供先を選んでください</h3>
          <Select value={destination} onChange={handleSelectChange} sx={{
                width: '100%',
                padding: '0',
                marginTop: '1.25rem',
                borderRadius: '0.3125rem',
                border: 'none',
          }}>
            <MenuItem value={'-'}>選択してください</MenuItem>
            {school != '十文字中学校' && school != '高田高校' && <MenuItem value={'全教研'}>全教研</MenuItem> }
            {school != '九州文化学園小学校' && school != '高田高校' && <MenuItem value={'東大NETアカデミー（中学校）'}>東大NETアカデミー（中学校）</MenuItem>}
            {school != '九州文化学園小学校' && school != '十文字中学校' && <MenuItem value={'東大NETアカデミー（高等学校）'}>東大NETアカデミー（高等学校）</MenuItem>}
          </Select>
        </div>
        <div className="checkbox-group" style={{display: destination && destination != '-' ? 'block' : 'none'}}>
            <h3>提供する個人情報を選択してください</h3>
            <FormGroup>
              <FormControlLabel control={<Checkbox color='success' name="name" checked={state.name} onChange={handleChange} sx={{'.MuiSvgIcon-root':{color: state.name ? '#2e7d32' : '#ccc'}}}/>} label="個人データ"></FormControlLabel>
              <p>個人データとは、氏名、学校名、学年、クラスを指します。</p>
              <p style={{marginTop: '10px'}}>提供した個人データは、提供先で本人確認に使用されます。</p>
              <div className="checkbox-title">成績データ</div>
              <FormControlLabel control={<Checkbox color='success' name="testResults" checked={state.testResults} onChange={handleChange} sx={{'.MuiSvgIcon-root':{color: state.testResults ? '#2e7d32' : '#ccc'}}}/>} label="学校テスト結果"></FormControlLabel>
              <p>提供した学校テスト結果の情報は、提供先であなたへのアドバイスにのみ使用されます。</p>
              <p style={{color: 'red', marginTop: '10px'}}>学校テスト結果には、テスト結果の、氏名・学年・クラス・出席番号が記載されたスキャンデータが含まれます。よって、本項目を選択する場合は、自動的に上記該当個人データが選択されます。</p>
              <FormControlLabel control={<Checkbox color='success' name="learningData" checked={state.learningData} onChange={handleChange} sx={{'.MuiSvgIcon-root':{color: state.learningData ? '#2e7d32' : '#ccc'}}}/>} label="学習状況データ"></FormControlLabel>
              <p>提供した学習状況データの情報は、提供先であなたへのアドバイスにのみ使用されます。</p>
            </FormGroup>            
        </div>          
        <CuBtn variant="contained" disabled={destination && destination != '-' ? false : true} onClick={handleClick}>データを提供する</CuBtn>
      </div>
      {warnOpen && <div style={{position: 'absolute', top: '0', background: 'rgba(0,0,0,0.05)', width: '100%', height: '100%'}}></div>}      
    </div>
  )
}

export default Share;