import { createSlice } from "@reduxjs/toolkit";
import { setToken, getToken, getUserInfo, setRefresgToken, getRefresgToken, setUserInfo } from "@/utils/token";

const userStore = createSlice({
  name: 'user',
  initialState:{
    userInfo: getUserInfo() || {},
    token: getToken() || '',
    refToken: getRefresgToken() || '',
    curTab: '/'
  },
  reducers:{
    setUser(state, action){
      state.userInfo = action.payload.userInfo;
      setUserInfo(action.payload.userInfo)
    },
    setReqToken(state, action){
      state.token = action.payload.token
      state.refToken = action.payload.refreshToken
      setToken(action.payload.token);
      setRefresgToken(action.payload.refreshToken)
    },
    setCurTab(state, action){
      state.curTab = action.payload
    }
  }
})

const {setUser, setReqToken, setCurTab} = userStore.actions;
export {setUser, setReqToken, setCurTab}
const userReducer = userStore.reducer;
export default userReducer;