
import axios from "axios";
import { getToken, clearToken } from "./token";
import { showLoading, hideLoading } from "./loading";
import { showErrDialog } from "./dialog";


let apiBaseUrl = process.env.REACT_APP_POC_HOST;
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 9000 // 请求超时时间
})

// request interceptor
service.interceptors.request.use(config => {
  
  const token = getToken();
  console.log("==axios==", token, config.headers)
  if (token) {
    config.headers[ 'authorization' ] = "Bearer" + ' ' + token
  }
  showLoading();
  return config;
}, (error) => {
  hideLoading()
  return Promise.reject(error)
})

const err = (error) => {
  console.log("=====", error)
  hideLoading()
  if (error.response) { // The request was made and the server responded with a status code
    //alert(error.response.status)
    if (error.response.status == 401) { // token is invalid
      clearToken();
      location.href = "/login"
      return "";
    }
  } else if (error.request) { // The request was made but no response was received
    showErrDialog("サーバーに接続できません。インターネット接続をご確認ください。");
    return "";
  }
  return Promise.reject(error)
}

service.interceptors.response.use((response) => {
  hideLoading()
  return response.data
}, err)

export default service;