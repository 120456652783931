import { Button } from "@mui/material";

function CustButton(props){

  return (
    <Button className="form_input" sx={{
      fontFamily: 'Noto Sans',
      background: '#613dcc',
      height: '2.5rem',
      borderRadius: '1.75rem',
      fontSize: '1rem',
      fontWeight: 700,
      color: '#FFFFFF',
      textAlign: 'center',
      marginBottom: '1.25rem',
      '&:hover': {
        backgroundColor: '#613dcc'
      }
    }} {...props}></Button>
  )
}

export default CustButton;