import { useNavigate } from "react-router-dom";
import './index.scss';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CustButton from "@/compoment/CustButton";
import { setCurTab } from "@/store/modules/userStore";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function PasswordResetComplete() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurTab("/password_reset"));
  }, [])

  const handleBack = () => {
    navigate('/password_reset')
  }
  const handleLogin = () => {
    navigate('/login')
  }

  return (
    <div className="password_reset_complete">
      <div className='header'>
        <KeyboardArrowLeftIcon className='back' onClick={handleBack}/>
        <div className='title'>パスワード再設定完了</div>
      </div>
      <div  className='tip'>
        <p>
          パスワード再設定が完了しました。
        </p>
        <p>
          下記のボタンより再度ログインしてください。
        </p>
      </div>
      <div className='btn_area'>
        <CustButton fullWidth onClick={handleLogin}>ログイン画面へ戻る</CustButton>
      </div>
    </div>
  )
}

export default PasswordResetComplete;