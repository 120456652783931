const TOKENKEY = "poc_token_key";
const TOKENREFRESHKEY = "poc_refresh_token_key";
const USERKEY = "poc_user_key";

function setToken(token){
  return sessionStorage.setItem(TOKENKEY, token)
}
function setRefresgToken(token){
  return sessionStorage.setItem(TOKENREFRESHKEY, token)
}
function setUserInfo(userInfo){ // {userId  userName}
  return sessionStorage.setItem(USERKEY, JSON.stringify(userInfo))
}

function getToken(){
  return sessionStorage.getItem(TOKENKEY);
}
function getRefresgToken(){
  return sessionStorage.getItem(TOKENREFRESHKEY);
}
function getUserInfo(){
  var localUserInfo = sessionStorage.getItem(USERKEY);
  if (localUserInfo == null || localUserInfo == undefined || localUserInfo == "undefined") {
    return {};
  }
  return JSON.parse(sessionStorage.getItem(USERKEY));
}

function clearToken(){
  sessionStorage.removeItem(TOKENKEY);
  sessionStorage.removeItem(TOKENREFRESHKEY);
  sessionStorage.removeItem(USERKEY);
  return true;
}

export {setToken, setRefresgToken, setUserInfo, getToken, getRefresgToken, getUserInfo, clearToken}