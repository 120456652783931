
// 半角英数記号
export const checkStringValidator = function (val) {
  const reg = /[^\x00-\xFF]/;
  if (val.match(reg) != null) {
    return false;
  }
  return true;
};

// 10文字以上100文字以下  半角英字、半角英数字、半角特殊文字（^ $ * . [ ] { } ( ) ? ! @ # % & / \ , > < ' : ; | _ ~ ` = + - ）をそれぞれ1つ以上
export const passwordValidator = function (val) {
  const reg =
  /^(?!\s+)(?!.*\s+$)(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{10,100}$/;
  if (val.match(reg) == null) {
    return false;
  }
  return true;
};
