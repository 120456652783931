import { useEffect, useRef } from 'react';
import './index.scss';
import * as echarts from "echarts";

function BarLine(props) {
  const options = props.option;
  const chartRef = useRef(null);
  let echartInstance = null;

  useEffect(() => {    
    echartInstance = echarts.getInstanceByDom(chartRef.current);
    if (echartInstance  == null) {
      echartInstance = echarts.init(chartRef.current);
    }
    echartInstance.resize();
    
    if (props.option != null) {
      echartInstance.setOption(props.option);
    }
    
  }, [options])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (echartInstance != null) {
        echartInstance.resize();
      }
    });
  }, [])

  return (
    <div ref={chartRef} style={{width:'100%', height:'100%'}}></div>
  )
}

export default BarLine;