import { useEffect } from "react";

function Setting() {

  useEffect(() => {
    document.title = '教育PDS実証実験用アプリ'
  })

  return (
      <div style={{fontSize: '16px'}}>Setting</div>
  )
}

export default Setting;