import { useNavigate } from "react-router-dom";
import './index.scss';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import Msg from "@/common/Message";
import { passwordValidator } from "@/utils/validator";
import { postAction } from "@/api/manage"
import CustInput from "@/compoment/CustInput";
import CustButton from "@/compoment/CustButton";
import { setCurTab } from "@/store/modules/userStore";
import { useDispatch } from "react-redux";
import { getUserInfo } from "@/utils/token";
import { showErrDialog } from "@/utils/dialog";

function PasswordReset() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pwd, setPwd] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [pwdNew, setPwdNew] = useState("");
  const [pwdNewError, setPwdNewError] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("")
  const [pwdConfirmError, setPwdConfirmError] = useState("");

  useEffect(() => {
    dispatch(setCurTab("/password_reset"));
  }, [])

  const handleBack = () => {
    navigate('/login')
  }
  const handleConfirm = async () => {
    if (validConfirm()) {
      var userInfo = getUserInfo();
      if (!userInfo || !userInfo.userId) { // キャッシュにはユーザ情報がない場合
        navigate('/login')
      } else {
        postAction('/change-password', {userid: userInfo.userId, oldpassword: pwd, newpassword: pwdNew}).then(res => {
          console.log("aaa", res)
          if (res && res.userId == userInfo.userId) {
            // 页面迁移
            navigate('/password_reset_complete')
          } else {
            showErrDialog(Msg.get("E-901"))
          }
        }).catch(err => {
          console.log("bbb", err)
          if (err.response && err.response.data.indexOf("EC132-101-101") != -1) { // The specified password is invalid
            showErrDialog(Msg.get("E-004"))
          } else if (err.response && err.response.data.indexOf("EC131-001-101") != -1) { // The specified user id does not exist
            showErrDialog(Msg.get("E-502"))
          } else if (err.response && err.response.data.indexOf("EC132-101-301") != -1) { // It is not allowed to set the same password in the past
            showErrDialog(Msg.get("E-009"))
          } else {
            showErrDialog(Msg.get("E-901"))
          }
        })
      }
    }
  }
  const validConfirm = () => {
    let checkRet = true;
    // password check
    if (_.isEmpty(pwd)) {
      setPwdError(Msg.get("E-003"));
      checkRet = false;
    } else {
      setPwdError("");
    }

    // password new check
    if (_.isEmpty(pwdNew)) {
      setPwdNewError(Msg.get("E-007"));
      checkRet = false;
    } else if (!passwordValidator(pwdNew)) {
      setPwdNewError(Msg.get("E-006"));
      checkRet = false;
    } else {
      setPwdNewError("");
    }

    // password confirm check
    if (_.isEmpty(pwdConfirm)) {
      setPwdConfirmError(Msg.get("E-008"));
      checkRet = false;
    } else if (pwdNew != pwdConfirm) {
      setPwdConfirmError(Msg.get("E-005"));
      checkRet = false;
    } else {
      setPwdConfirmError("");
    }

    return checkRet;
  }

  return (
      <div className="password_reset">
        <div className='header'>
          {/* <KeyboardArrowLeftIcon className='back' onClick={handleBack}/> */}
          <div className='title'>パスワード再設定</div>
        </div>
        <div  className='tip'>
          <p>
            現在のパスワードと新しいパスワードを入力してください。
          </p>
          <p>
            ※パスワードは10文字以上、英字、数字、記号をそれぞれ最低1つずつ含める必要があります。
          </p>
        </div>

        <div className='input_area'>
          <div className="form_item">
            <div className="form_label">現在のパスワード</div>          
            <CustInput error={pwdError} type="password" placeholder="現在のパスワードを入力してください。" maxLength={100} value={pwd} onChange={(e) => {setPwd(e.target.value)}}></CustInput>
          </div>
          <div className="form_item">
            <div className="form_label">新しいパスワード</div>          
            <CustInput error={pwdNewError} type="password" placeholder="新しいパスワードを入力してください。" maxLength={100} value={pwdNew} onChange={(e) => {setPwdNew(e.target.value)}}></CustInput>
          </div>
          <div className="form_item">
            <div className="form_label">新しいパスワード（確認用）</div>          
            <CustInput error={pwdConfirmError} type="password" placeholder="新しいパスワードを入力してください。" maxLength={100} value={pwdConfirm} onChange={(e) => {setPwdConfirm(e.target.value)}}></CustInput>
          </div>
          <div className='btn_area'>
            <CustButton fullWidth onClick={handleConfirm}>送信</CustButton>
          </div>          
        </div>
      </div>
  )
}

export default PasswordReset;